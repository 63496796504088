<template>
  <div>
    <div style="display: flex">
      <!-- 左侧菜单 -->
      <div
        :style="{
          width: isCollapse ? '64px' : '250px',
          transition: 'all 0.3s ease',
          height: '100vh',
        }"
      >
        <div v-if="isCollapse === false" style="padding: 25px">
          <img src="@/assets/logo1.png" alt="logo" style="width: 100%" />
        </div>
        <div v-else style="padding: 10px">
          <img src="@/assets/logo3.png" alt="" style="width: 100%" />
        </div>
        <el-menu
          default-active="1"
          class="scroll-container"
          :collapse="isCollapse"
          style="height: calc(100vh - 100px); overflow: auto"
        >
          <el-menu-item @click="isCollapse = !isCollapse">
            <i class="el-icon-more"></i>
            <span v-if="isCollapse === true" slot="title">展开</span>
            <span v-else slot="title">收起</span>
          </el-menu-item>
          <el-menu-item index="1" @click="open(1)">
            <i class="el-icon-menu"></i>
            <span slot="title">控制台</span>
          </el-menu-item>
          <el-menu-item index="2" @click="open(2)">
            <i class="el-icon-s-custom"></i>
            <span slot="title">会员管理</span>
          </el-menu-item>
          <el-menu-item index="3" @click="open(3)">
            <i class="el-icon-user"></i>
            <span slot="title">代理管理</span>
          </el-menu-item>
          <el-menu-item index="4" @click="open(4)">
            <i class="el-icon-s-goods"></i>
            <span slot="title">产品管理</span>
          </el-menu-item>
          <el-menu-item index="5" @click="open(5)">
            <i class="el-icon-s-check"></i>
            <span slot="title">服务管理</span>
          </el-menu-item>
          <el-menu-item index="6" @click="open(6)">
            <i class="el-icon-s-order"></i>
            <span slot="title">订单管理</span>
          </el-menu-item>
          <el-menu-item index="7" @click="open(7)">
            <i class="el-icon-cpu"></i>
            <span slot="title">云服务器管理</span>
          </el-menu-item>
          <el-menu-item index="8" @click="open(8)">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">新闻管理</span>
          </el-menu-item>
          <el-menu-item index="9" @click="open(9)">
            <i class="el-icon-school"></i>
            <span slot="title">极速学院管理</span>
          </el-menu-item>
          <el-menu-item index="10" @click="open(10)">
            <i class="el-icon-receiving"></i>
            <span slot="title">充值订单管理</span>
          </el-menu-item>
          <el-menu-item index="11" @click="open(11)">
            <i class="el-icon-picture-outline"></i>
            <span slot="title">首页轮播管理</span>
          </el-menu-item>
        </el-menu>
      </div>
      <!-- 右侧内容显示 -->
      <div
        style="flex: 1; width: 100vh; height: 100vh; background-color: white"
      >
        <div style="text-align: right">
          <el-popover placement="top-start" trigger="hover">
            <div>
              <p style="font-size: 16px; margin: 0">
                当前登录用户:<em
                  ><b>{{ username }} </b></em
                >
              </p>
              <el-button
                type="warning"
                style="width: 100%"
                @click="bindEmailDialog = true"
                >邮箱绑定</el-button
              >
              <br />
              <el-button
                type="danger"
                style="width: 100%; margin-top: 10px"
                @click="changPwdDialog = true"
                >修改密码</el-button
              >
              <br />
              <el-button
                type="success"
                style="width: 100%; margin-top: 10px"
                @click="LogOuts()"
                >退出登录</el-button
              >
            </div>
            <el-image
              :src="avatar"
              style="width: 70px; height: 70px; border-radius: 50px"
              slot="reference"
              @error="avatarError"
            ></el-image>
            <p slot="reference" style="float: right; margin: 20px 0 0 10px">
              {{ time }}
              <span style="font-weight: bold; font-style: italic">{{
                username
              }}</span>
            </p>
          </el-popover>
        </div>
        <el-tabs
          v-if="isShow == true"
          v-model="editableTabsValue"
          type="card"
          editable
          @edit="handleTabsEdit"
        >
          <el-tab-pane
            :key="item.name"
            v-for="item in editableTabs"
            :label="item.title"
            :name="item.name"
          >
            <component :is="item.component"></component>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 邮箱绑定弹窗 -->
    <el-dialog
      title="邮箱绑定"
      :visible.sync="bindEmailDialog"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-alert
        :title="
          '您正在为用户' + username + '绑定邮箱，以便更安全地登录管理后台'
        "
        type="warning"
        effect="dark"
        :closable="false"
      >
      </el-alert>
      <el-form label-width="70px" style="margin-top: 20px">
        <el-form-item label="邮箱账号">
          <el-input v-model="bind.email"></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input v-model="bind.captcha">
            <el-button
              style="padding-right: 10px"
              slot="suffix"
              type="text"
              @click="sendCaptcha('bind')"
              >发送验证码
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bindEmails()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 邮箱验证弹窗 -->
    <el-dialog
      title="邮箱登录验证"
      :visible.sync="emailCheckDialog"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-alert
        :title="
          '您的管理员账号' +
          username +
          '正在登录后台管理，为确保安全登录，请完成邮箱验证码验证'
        "
        type="warning"
        effect="dark"
        :closable="false"
      >
      </el-alert>
      <el-form label-width="70px" style="margin-top: 20px">
        <el-form-item label="邮箱账号">
          <el-input v-model="mail" disabled></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input v-model="check.captcha">
            <el-button
              style="padding-right: 10px"
              slot="suffix"
              type="text"
              @click="sendCaptcha('login')"
              >发送验证码
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="emailCheckDialog = false"
          >临时跳过</el-button
        >
        <el-button type="primary" @click="login()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="changPwdDialog" width="30%">
      <el-form label-width="100px">
        <el-form-item label="旧密码">
          <el-input type="password" v-model="reset.oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input type="password" v-model="reset.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码">
          <el-input type="password" v-model="reset.newPassword1"></el-input>
          <span
            v-if="reset.newPassword != reset.newPassword1"
            style="color: red"
            >两次密码输入不一致！</span
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changPwdDialog = false">取 消</el-button>
        <el-button type="primary" @click="changePwds()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DashBoard from "@/components/DashBoard.vue";
import MemberVue from "@/components/MemberVue.vue";
import AgentVue from "@/components/AgentVue.vue";
import ProductVue from "@/components/ProductVue.vue";
import ServeVue from "@/components/ServeVue.vue";
import OrderVue from "@/components/OrderVue.vue";
import ServerVue from "./ServerVue.vue";
import NewsVue from "./NewsVue.vue";
import JisuCollege from "./JisuCollege.vue";
import RechargeOrder from "./RechargeOrder.vue";
import SwipeVue from "./SwipeVue.vue";
import {
  bindEmail,
  changePwd,
  emailLogin,
  getEmailByUsername,
  send,
} from "@/api/api";
export default {
  data() {
    return {
      isCollapse: false,
      editableTabs: [], // 初始化为空数组
      editableTabsValue: "1",
      isShow: true,
      componentsList: {
        1: "DashBoard",
        2: "MemberVue",
        3: "AgentVue",
        4: "ProductVue",
        5: "ServeVue",
        6: "OrderVue",
        7: "ServerVue",
        8: "NewsVue",
        9: "JisuCollege",
        10: "RechargeOrder",
        11: "SwipeVue",
      },
      currentView: "DashBoard",
      time: "",
      dialogVisible: false,
      email: "",
      bindEmailDialog: false,
      bind: [],
      emailCheckDialog: false,
      check: [],
      changPwdDialog: false,
      reset: [],
    };
  },
  methods: {
    changePwds() {
      const token = localStorage.getItem("token");
      changePwd(token, this.reset.oldPassword, this.reset.newPassword).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功，即将退出登录！");
            this.changPwdDialog = false;
            setTimeout(() => {
              this.LogOuts();
            }, 1000);
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
    login() {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      emailLogin(this.username, this.email, this.check.captcha).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("验证通过！");
          this.emailCheckDialog = false;
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    maskedEmail() {
      this.mail = this.email.replace(/^(.{3})(.*)(@.*)$/, "$1*******$3");
    },
    bindEmails() {
      if ((this.bind.email, this.bind.captcha)) {
        const loading = this.$loading({
          lock: true,
          text: "正在处理",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        bindEmail(this.username, this.bind.email, this.bind.captcha).then(
          (res) => {
            if (res.data.code === 200) {
              this.$message.success("绑定成功！");
              this.bindEmailDialog = false;
              loading.close();
            } else {
              this.$message.error("绑定失败！" + res.data.msg);
              loading.close();
            }
          }
        );
      } else {
        this.$message.error("请输入邮箱和验证码！");
      }
    },
    sendCaptcha(event) {
      const loading = this.$loading({
        lock: true,
        text: "正在发送验证码",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      if (event === "bind") {
        send(this.bind.email, "bind").then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "验证码发送成功",
              type: "success",
            });
            loading.close();
          } else {
            this.$message({
              message: "验证码发送失败",
              type: "error",
            });
            loading.close();
          }
        });
      } else if (event === "login") {
        send(this.email, "login").then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "验证码发送成功",
              type: "success",
            });
            loading.close();
          } else {
            this.$message.error("验证码发送失败" + res.data.msg);
            loading.close();
          }
        });
      }
    },
    getEmailByUsernames() {
      const loading = this.$loading({
        lock: true,
        text: "正在验证当前用户",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      getEmailByUsername(token, this.username).then((res) => {
        if (res.data.code === 200) {
          if (res.data.data) {
            this.email = res.data.data;
            this.maskedEmail();
            // 邮箱登录验证弹窗
            console.log("已经绑定，需要验证邮箱");
            this.emailCheckDialog = true;
            loading.close();
          } else {
            this.$message.error("需要绑定邮箱！");
            this.bindEmailDialog = true;
            loading.close();
          }
        } else {
          this.$message.error("邮箱获取失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    greeting() {
      const currentHour = new Date().getHours();
      if (currentHour >= 0 && currentHour < 6) {
        this.time = "凌晨好";
      } else if (currentHour >= 6 && currentHour < 12) {
        this.time = "早上好";
      } else if (currentHour >= 12 && currentHour < 18) {
        this.time = "下午好";
      } else {
        this.time = "晚上好";
      }
    },
    avatarError() {
      this.avatar = require("@/assets/defaultUser.jpg");
      console.log("触发加载失败，头像已经替换");
    },
    LogOuts() {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("avatar");
      localStorage.removeItem("nickname");
      this.$message.success("退出登录成功，返回极速棋牌官网");
      this.$router.push("/");
      window.location.reload();
      // const token = localStorage.getItem("token");
      // LogOut(token).then((res) => {
      //   if (res.data.code === 1) {
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("username");
      //     localStorage.removeItem("avatar");
      //     localStorage.removeItem("isLoggedIn");
      //     this.$message.success("退出登录成功");
      //     this.$router.push("/login");
      //   } else {
      //     this.$message.error(res.data.msg);
      //     localStorage.removeItem("token");
      //     localStorage.removeItem("username");
      //     localStorage.removeItem("avatar");
      //     localStorage.removeItem("isLoggedIn");
      //     this.$message.success("退出登录成功");
      //     this.$router.push("/login");
      //   }
      // });
    },
    open(code) {
      let existingTab = this.editableTabs.find(
        (tab) => tab.name === this.componentsList[code]
      );
      if (!existingTab) {
        this.currentView = this.componentsList[code];
        let menuName = {
          1: "控制台",
          2: "会员管理",
          3: "代理管理",
          4: "产品管理",
          5: "服务管理",
          6: "订单管理",
          7: "云服务器管理",
          8: "新闻管理",
          9: "极速学院管理",
          10: "充值订单管理",
          11: "首页轮播管理",
        };
        this.editableTabs.push({
          title: menuName[code],
          name: this.componentsList[code],
          component: this.componentsList[code],
        });
        this.editableTabsValue = this.componentsList[code];
      } else {
        this.editableTabsValue = existingTab.name;
      }
    },
    handleTabsEdit(targetName, action) {
      if (action === "add") {
        let newTabName = ++this.editableTabs.length;
        this.editableTabs.push({
          title: "New Tab", // 这里可以根据需要设置新的标签页标题
          name: newTabName,
          component: "NewTabComponent", // 你需要替换为实际的组件名称
        });
        this.editableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      }
    },
  },
  created() {
    this.open(1);
    this.username = localStorage.getItem("username");
    this.avatar = localStorage.getItem("avatar");
    this.greeting();
    this.getEmailByUsernames();
  },
  mounted() {},
  components: {
    DashBoard,
    MemberVue,
    AgentVue,
    ProductVue,
    ServeVue,
    OrderVue,
    ServerVue,
    NewsVue,
    JisuCollege,
    RechargeOrder,
    SwipeVue,
  },
};
</script>

<style>
/* 全局滚动条美化 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

/* 隐藏滚动条 */
.scroll-container {
  overflow: auto;
  scrollbar-width: none; /* Firefox 隐藏滚动条 */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Chrome 隐藏滚动条 */
}
</style>
