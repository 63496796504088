<template>
  <div>
    <!-- 数据查看表格 -->
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="代理ID" width="80"> </el-table-column>
      <el-table-column prop="email" label="邮箱账号"> </el-table-column>
      <el-table-column prop="createtime" label="成为代理时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="logintime" label="代理最后登录时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.logintime) }}
        </template>
      </el-table-column>
      <el-table-column prop="subordinate_count" label="下级数量">
      </el-table-column>
      <el-table-column prop="money" label="总佣金"> </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button
            type="warning"
            :disabled="!scope.row.subordinate_count != 0"
            @click="openAgentListDialog(scope.row.aid)"
            >查看下级</el-button
          >
          <el-button type="primary" @click="checkAgent(scope.row)"
            >详细信息</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 单个用户详细弹窗 -->
    <el-dialog title="用户详细信息" :visible.sync="agentDialog" width="30%">
      <p>数据库id:{{ agent.id }}</p>
      <p>用户id:{{ agent.uid }}</p>
      <p>用户名:{{ agent.username }}</p>
      <p>用户邮箱:{{ agent.email }}</p>
      <p>
        代理等级:
        <span v-if="agent.level === 1">银牌代理</span>
        <span v-else-if="agent.level === 2">金牌代理</span>
        <span v-else-if="agent.level === 3">白金代理</span>
        <span v-else-if="agent.level === 4">钻石代理</span>
      </p>
      <p>上次登录时间:{{ formatDate(agent.prevtime) }}</p>
      <p>登录IP地址:{{ agent.loginip }}</p>
      <p>注册时间:{{ formatDate(agent.createtime) }}</p>
      <p>注册IP地址:{{ agent.joinip }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="agentDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看代理表格弹窗 -->
    <el-dialog
      title="用户详细信息"
      :visible.sync="agentTableDialog"
      width="50%"
    >
      <el-table
        :data="agentList"
        v-loading="aloading"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="uid" label="ID" width="80"> </el-table-column>
        <el-table-column prop="email" label="邮箱账号"> </el-table-column>
        <el-table-column prop="createtime" label="注册时间">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createtime) }}
          </template>
        </el-table-column>
        <el-table-column prop="logintime" label="登录时间">
          <template slot-scope="scope">
            {{ formatDate(scope.row.logintime) }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="余额" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.amount }} USDT</span> </template
          >>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 'normal'" type="success"
              >正常</el-tag
            >
            <el-tag v-else-if="scope.row.status === 'ban'" type="danger"
              >封禁</el-tag
            >
            <el-tag v-else type="warning">异常</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="agentTableDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { getAgents, getSubordinateAgents } from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1, // 当前页码
      pageSize: 10, // 每页条数
      total: 0, // 总记录数
      agentDialog: false,
      agent: [],
      agentTableDialog: false,
      agentList: [],
      aloading: false,
    };
  },
  methods: {
    openAgentListDialog(uid) {
      this.aloading = true;
      this.agentTableDialog = true;
      const token = localStorage.getItem("token");
      getSubordinateAgents(token, uid)
        .then((res) => {
          if (res.data.code === 200) {
            this.agentList = res.data.data;
            this.aloading = false;
          } else {
            this.$message.error("请求失败！" + res.data.msg);
            this.aloading = false;
          }
        })
        .catch((err) => {
          this.$message.error("请求失败！" + err.data.msg);
          this.aloading = false;
        });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    checkAgent(row) {
      this.agent = row;
      this.agentDialog = true;
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.getAgent(); // 请求新页面的数据
    },
    getAgent() {
      this.loading = true;
      getAgents(this.page, this.pageSize)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.$message.error("获取代理列表失败" + res.data.msg);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error("出现错误" + err.data.msg);
          this.loading = false;
        });
    },
  },
  created() {
    this.getAgent();
  },
};
</script>

<style></style>
