<template>
  <div>
    <h1>新闻管理</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style></style>
