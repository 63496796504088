import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.jisuqipai.online/",
});

// 登录接口
export const Login = (username, password) => {
  return axiosInstance.post("/api/admin/login", {
    username: username,
    password: password,
  });
};

// 登录接口
export const getEmailByUsername = (token, username) => {
  return axiosInstance.post(
    "/api/admin/getEmailByUsername",
    {
      username: username,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 验证码发送接口
export const send = (email, event) => {
  return axiosInstance.post("/api/ems/sendTest", {
    email: email,
    event: event,
  });
};

// 绑定邮箱
export const bindEmail = (username, email, captcha) => {
  return axiosInstance.post("/api/admin/bindEmail", {
    username: username,
    email: email,
    captcha: captcha,
  });
};

// 邮箱登录验证接口
export const emailLogin = (username, email, captcha) => {
  return axiosInstance.post("/api/admin/emailLogin", {
    username: username,
    email: email,
    captcha: captcha,
  });
};

// 修改密码
export const changePwd = (token, oldPassword, newPassword) => {
  return axiosInstance.post("/api/admin/changePwd", {
    token: token,
    oldPassword: oldPassword,
    newPassword: newPassword,
  });
};

// 获取用户数据
export const getUsers = (page, pageSize) => {
  return axiosInstance.post("/api/admin/getUsers", {
    page: page,
    pageSize: pageSize,
  });
};

// 对用户更改账户状态
export const changeUserStatus = (token, uid, status) => {
  return axiosInstance.post("/api/admin/changeUserStatus", {
    token: token,
    uid: uid,
    status: status,
  });
};

// 获取代理数据
export const getAgents = (page, pageSize) => {
  return axiosInstance.post("/api/admin/getAgents", {
    page: page,
    pageSize: pageSize,
  });
};

// 根据uid获取下级代理
export const getSubordinateAgents = (token, uid) => {
  return axiosInstance.post("/api/admin/getSubordinateAgents", {
    token: token,
    uid: uid,
  });
};

// 获取极速学院视频列表
export const getSchoolVideos = (page, pageSize) => {
  return axiosInstance.post("/api/admin/getSchoolVideos", {
    page: page,
    pageSize: pageSize,
  });
};

// 通过id查看极速学院子视频id
export const getSchoolVideoById = (id) => {
  return axiosInstance.post("/api/admin/getSchoolVideoById", {
    id: id,
  });
};

// 上传极速学院子视频
export const uploadSchoolVideo = (token, id, title, file) => {
  return axiosInstance.post("/api/admin/uploadSchoolVideo", {
    token: token,
    id: id,
    title: title,
    file: file,
  });
};

// 编辑极速学院子视频
export const editSchoolVideo = (token, id, title, file) => {
  return axiosInstance.post("/api/admin/editSchoolVideo", {
    token: token,
    id: id,
    title: title,
    file: file,
  });
};

// 删除视频选集
export const delvideos = (token, id) => {
  return axiosInstance.post("/api/admin/delvideos", {
    token: token,
    id: id,
  });
};

// 获取极速学院视频分类
export const getVideoType = () => {
  return axiosInstance.post("/api/admin/getVideoType");
};

// 添加极速学院视频
export const addvideo = (token, title, tag, img, price, type) => {
  return axiosInstance.post("/api/admin/addvideo", {
    token: token,
    title: title,
    tag: tag,
    img: img,
    price: price,
    type: type,
  });
};

// 删除极速学院视频
export const delvideo = (token, id) => {
  return axiosInstance.post("/api/admin/delvideo", {
    token: token,
    id: id,
  });
};
