import Vue from "vue";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router/router.js";
import App from "./App.vue";

// Vue.use(TDesign);
Vue.use(ElementUI);

// 配置axios全局使用
Vue.prototype.$http = axios;

new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
});
