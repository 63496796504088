<template>
  <div>
    <!-- 数据查看表格 -->
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="uid" label="ID" width="80"> </el-table-column>
      <el-table-column prop="email" label="邮箱账号"> </el-table-column>
      <el-table-column prop="pid" label="上级id" width="80"> </el-table-column>
      <el-table-column prop="loginip" label="登录IP"> </el-table-column>
      <el-table-column prop="joinip" label="注册IP"> </el-table-column>
      <el-table-column prop="createtime" label="注册时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="logintime" label="登录时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.logintime) }}
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="余额" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.amount }} USDT</span> </template
        >>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="80">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 'normal'" type="success"
            >正常</el-tag
          >
          <el-tag v-else-if="scope.row.status === 'ban'" type="danger"
            >封禁</el-tag
          >
          <el-tag v-else type="warning">异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button type="primary" @click="checkUser(scope.row)"
            >查看</el-button
          >
          <el-popconfirm
            title="确定对该用户执行封禁操作吗？"
            @confirm="changeUserStatu(scope.row.uid, 'ban')"
          >
            <el-button
              type="danger"
              slot="reference"
              :disabled="scope.row.status === 'ban'"
              style="margin-left: 10px"
              >封禁</el-button
            >
          </el-popconfirm>
          <el-popconfirm
            title="确定对该用户执行解禁操作吗？"
            @confirm="changeUserStatu(scope.row.uid, 'normal')"
          >
            <el-button
              type="success"
              slot="reference"
              :disabled="scope.row.status === 'normal'"
              style="margin-left: 10px"
              >解封</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 单个用户详细弹窗 -->
    <el-dialog title="用户详细信息" :visible.sync="userDialog" width="30%">
      <p>数据库id:{{ user.id }}</p>
      <p>用户id:{{ user.uid }}</p>
      <p>用户名:{{ user.username }}</p>
      <p>用户邮箱:{{ user.email }}</p>
      <p>用户等级:{{ user.level }}</p>
      <p>上次登录时间:{{ formatDate(user.prevtime) }}</p>
      <p>登录IP地址:{{ user.loginip }}</p>
      <p>注册时间:{{ formatDate(user.createtime) }}</p>
      <p>注册IP地址:{{ user.joinip }}</p>
      <p>
        用户状态:
        <span v-if="user.status === 'normal'">正常</span>
        <span v-else-if="user.status === 'ban'">封禁</span>
        <span v-else>异常</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="userDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { changeUserStatus, getUsers } from "@/api/api";
import moment from "moment";
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1, // 当前页码
      pageSize: 10, // 每页条数
      total: 0, // 总记录数
      userDialog: false,
      user: [],
    };
  },
  methods: {
    // 更改用户账户状态
    changeUserStatu(uid, status) {
      const loading = this.$loading({
        lock: true,
        text: "正在执行",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      changeUserStatus(token, uid, status)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("操作成功！");
            this.getUser();
            loading.close();
          } else {
            this.$message.error("操作失败！" + res.data.msg);
            loading.close();
          }
        })
        .catch((err) => {
          this.$message.error("出现错误！" + err.data.msg);
          loading.close();
        });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    checkUser(row) {
      this.user = row;
      this.userDialog = true;
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.getUser(); // 请求新页面的数据
    },
    getUser() {
      this.loading = true;
      getUsers(this.page, this.pageSize)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
            this.loading = false;
          } else {
            this.$message.error("获取用户列表失败" + res.data.msg);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error("出现错误" + err.data.msg);
          this.loading = false;
        });
    },
  },
  created() {
    this.getUser();
  },
};
</script>

<style></style>
